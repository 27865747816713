.Users {
    .block {
        font-size: 1.2em;
        &.block:first-of-type {
            margin-top: 2em !important;
            padding: 1.7em;
        }
        &.block:last-of-type{
            text-align: center !important;
        }
        .ui.loader {
            margin: 0px auto !important;
        }
        .searchBar {
            width: 100% !important;
     
            & > input {
                border-radius: .28571429rem !important;
            }
        }
        h3 {
            font-size: 1.2em !important;
            margin-bottom: .8em !important;
            &.user-add {
                @media screen and (max-width: 767px){
                    margin-top: 1em !important;
                }
            }
        }
        .ui.message {
            font-size: .8em !important;
        }
    }

    .ui.form {
        max-width: 450px !important;
        margin-right: auto !important;
        margin-left: auto !important;
        .fields {
            margin-bottom: 0px !important;
            align-items: normal !important;
            .field {
                display: block !important;
                label {
                    display: none !important;
                }
            }
        }
        .fourteen.wide.field {
            @media screen and (max-width: 420px) {
                width: 84.5% !important;
            }
        }
        .ui.icon.button {
            margin-left: 0em !important;
            margin-right: auto !important;
            color: white !important;
            background-color: #2ed573 !important;
            height: 38px !important;
            font-size: 1rem !important;
            @media screen and (max-width: 767px) {
                margin-left: auto !important;
                margin-right: 0em !important;
            }
        }
    }

    .ui.table {
        font-size: .9em !important;
        .userCell {
            word-break: break-all !important;
            .userName {
                text-transform: uppercase !important;
            }
        }
    }
}