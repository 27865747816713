.Widget-grid{
    margin: 1em 0 !important;
    .column{
        margin: 1em 0 !important
    }
    img{
        margin-top: -1em;
        &.ui.small.image{
            width: 50%;
            @media only screen and (max-device-width : 375px) {
                width: 60%;
            }
            height: auto;
        }
    }
    .title{
        font-size: 1.5em;
        &.center{
            margin-left: 0.5em;
        }
    }
    .header{
        font-size: 1.4em;
        font-weight: 700;
        margin-top: 0.5em;
        &.center{
            margin-left: 0.5em;
            font-size: 1.8em;
            color: #3683b6
        }
    }
    .description{
        margin-bottom: 1em;
        &.center{
            margin-left: 0.5em;
            font-size: 1.5em;
        }
    }
}