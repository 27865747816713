@import '~semantic-ui-css/semantic.css';

$color-background : #F8F8F8;
$color-default : #235677;
$color-default-light : #3683b6;
$color-grey : #404140;
$color-grey-light : #9e9e9e;

body{
    overflow-x: hidden;
    background-color:$color-background !important;
}

a{
    color: $color-grey !important;
    &:hover{
        color: $color-grey !important
    }
}

// Semantic
.ui.header{
    font-weight: 500 !important
}

.ui.dimmer {
    background-color: rgba(0,0,0,.45) !important;
}

.ui.input{
    input {
        border: 1px solid rgba(34,36,38,.35) !important;
        &::placeholder{
            color: rgba(34,36,38,.40) !important;
        }
    }
}
.ui.message{
    box-shadow: none !important
}
// Colors
.color-default{
    color: $color-default !important;
}

.color-default-light{
    color: $color-default-light !important;
}

.color-grey{
    color: $color-grey !important;
}

.color-grey-light{
    color: $color-grey-light !important;
}

// Shadow
.icon-shadow{
    text-shadow: 
    1px  1px     #fff, 
   -1px  1px     #fff, 
    1px -1px     #fff, 
   -1px -1px     #fff,
    1px  1px 5px rgba(0,0,0,0.70);   
}



.ui.checkbox input:focus ~ label:before{
    border-color: $color-grey-light !important;
}

.ui.checkbox input:checked ~ .box:after, .ui.checkbox input:checked ~ label:after{
   color: #235677 !important;
}

// Circular
.circular{
    color:white!important;
    margin-right: 1em !important;
    @media only screen and (max-device-width : 320px) {
        margin-right: 0!important;
    }
    @media screen and (max-width: 640px) {
        margin-right: 0 !important;
    }
    box-shadow:none !important;
    background-color: #d5d5d5 !important;
}

// Button
.btn{
    &.medium{
        font-size: 1.1em !important;
    }
    &.left{
        text-align: left !important;
    }
    &.default{
        color: white !important;
        background-color: $color-default !important;
    }
    &.custom{
        margin-bottom: 0.5em !important;
        background-color: white !important;
        color: black !important;
        font-weight: 500  !important;
        border-radius: 0.4em !important;
        box-shadow: 0 1px 2px 0 #CCCCCC !important;
        i{
            &.right{
                float: right !important;
            }
            &.circle{
                margin-right: 1em;
                @extend .icon-shadow
            }
        }
        }
    }
    .page {
        padding-top: 5rem !important;
        padding-bottom: 3rem !important;
    h2{
        font-weight: 200;
    }
    .lead{
        padding: 1.5rem 0 0.5rem 0 !important;
        color:  white  !important;
        font-weight: 200 !important;
    }

    .link{
        display: block;
        width: 100%;
        background-color: white;
        border-radius: 0.4em;
        box-shadow: 0 1px 2px 0 #CCCCCC;
        .header{
            font-size: 1em
        }
        .content{
            font-size: 0.8em 
        }
        &.center{
            @media only screen and (max-device-width : 640px) {
                text-align: center;
            }
        }
        &.small{
            margin: 0.8em 0;
            font-size: 1.3em;
            padding: 1em 
        }
        &.medium{
            margin: 0.8em 0;
            font-size: 1.4em;
            padding: 1em 
        }
        &.large {
            margin: 1em 0;
            font-size: 1.5em;
            padding: 1.5em 1em;
        }
        i{  
            &.right{
                float: right
            }
            &.circle{
                @extend .icon-shadow   
            }
        }
        .link-content{
            display: inline-block;
            @media only screen and (max-device-width : 640px) {
                display: block;
                padding-top: 0.5em
            }
        }
        &.alert{
            color:white !important;
            background-color: #ff626f !important;
        }
    }
}

// Background gradient color
.bg-gradient{  
    background: linear-gradient(
    to bottom, 
    $color-default, 
    $color-default-light 250px, 
    $color-background 0%, 
    $color-background 100vh);
}

// Loader
.ui.loader {
    color: grey !important;
    &.loader:after{
        border-color: $color-default-light transparent transparent !important;
    }
    &.loader:before{
        border-color: #CCCCCC !important
    }
}

// Block
.block {
    margin: 0.8em auto;
    padding: 1.8em 1.8em 1em 1.8em;
    display: block;
    width: 100%;
    background-color: #fff;
    border-radius: 0.4em;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
    border: 1px solid rgba(34, 36, 38, 0.15);
    h3 {
        font-size: 1.2em !important;
    }
    .two{
        &.column{
            margin-bottom: 1.2em;
        }
    }
    .ui.loader {
        margin: 2em !important;
    }
}

// Modal
.modal-loader {
    .ui.loader {
        margin: 2em !important;
 }}