.Companies{
    .secondary.menu {
        .right{
            a{
                margin-top: 0.5em
            }
        }
        a.item{
            box-shadow: 0 0 0 1px rgba(34, 36, 38 ,0.2) inset !important;
        }
        a.item:not(.disabled) {
            color: grey !important;
            &:hover{
                color: grey !important;
                background-color: white !important;
            }
        }
    }
    .ui.circular.label{
        min-height: initial;
        min-width: initial;
    }
    .download{
        text-align: right!important;
        display: block !important
    }
    .ui.page.grid{
        padding-left: 0% !important
    }
    .link{
        position: relative;
        color: #235677 !important;
    
        i.right{
            margin-top: -0.3em !important
        }
        .content{
            color: grey !important;
            margin-top: 0.3em !important
        }
    }
    .workforce{
        display: inline;
        margin-left: 0.5em
    }
    .keyFigures{
        & i.circle {text-shadow: none  !important};
        font-size: 0.8em;
        margin: 0.5em 0;
        color: grey !important;
    }
    h1 {
        margin-bottom: 0.5em !important
    }
    h2{
        margin-top: 0.5em !important
    }
    h3{
        background-color: #8a818a !important;
        color: white !important;
        display: inline-block !important;
        padding: 0.2em 1em !important;
        margin: 0 !important;
        border-radius: 2em;
    }
    .circle{
        margin-left:  0.5em !important;
    }
    .results {
        width: 100% !important;
    }
    .input{
        display: flex !important;
        &:focus{
            border-color: #235677 !important
        }
    }
    .ui.basic.button.disabled{
        box-shadow: 0 0 0 1px rgba(34, 36, 38 ,0.2) inset !important;
    }
    .knowledge-icons{
        padding-right: 0 !important;
        .ui.basic.button.disabled{
            box-shadow: 0 0 0 1px rgba(34, 36, 38 ,0.2) inset !important;
        }
        .icon{
            font-size: 0.9em;
            margin: 0 0.2em;
        }
    }
}
.CompaniesModal{
    .search.fluid{
        .input{
            width: 100% !important;
        }
    }
}