.Contact {
    .block {
        &.block:first-of-type {
            margin-top: 2em !important;
        }
        h3 {
            font-size: 1.8em !important;
            margin-bottom: 1em !important;
        }
    }

}