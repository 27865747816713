.SignInForm {
    .ui.form > .fields {
        .field {
            display: block !important;
            @media screen and (max-width: 767px) {
                margin-bottom: 1em !important;
            }
        }
        .field:last-of-type {
            @media screen and (max-width: 767px) {
                margin-bottom: 0em !important;
            }
        }
    }
    .copyright{ 
        margin-top: 2em;
        color: #999999;
    }
    .link{
        display: block;
        margin: 0.5em 0;
    }
}