$circle-width: 0.08em;
$circle-width-hover: 0.06em;

$primary-color: #3683b6;
$secondary-color: #D0CDCD;
$bg-color: white;
$text-color: #4A4A4A;

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin rotate($rotation) {
    -webkit-transform: rotate($rotation);
    -moz-transform: rotate($rotation);
    -ms-transform: rotate($rotation);
    -o-transform: rotate($rotation);
    transform: rotate($rotation);
}

@mixin box-sizing($box-sizing) {
    -webkit-box-sizing: $box-sizing;
    -moz-box-sizing: $box-sizing;
    box-sizing: $box-sizing;
}

.rect-auto {
    clip: rect(auto, auto, auto, auto);
}

.pie {
    position: absolute;
    border: $circle-width solid $primary-color;
    width: 1 - (2 * $circle-width);
    height: 1 - (2 * $circle-width);
    clip: rect(0em, 0.5em, 1em, 0em);
    border-radius: 50%;
    @include rotate(0deg);
}

.pie-fill {
    @include rotate(180deg);
}

// main
.c100 {
    *,
    *:before,
    *:after {
        @include box-sizing(content-box);
    }
    position: relative;
    font-size: 120px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    float: left;
    margin: 0 0.1em 0.1em 0;
    background-color: $secondary-color;
    // center circle to its parent
    &.center {
        float: none;
        margin: 0 auto;
    }
    // bigger size
    &.big {
        font-size: 240px;
    }
    // smaller size
    &.small {
        font-size: 80px;
    }
    // centered value inside circle
    >span {
        position: absolute;
        width: 100%;
        z-index: 1;
        left: 0;
        top: 0;
        width: 5em;
        line-height: 5em;
        font-size: 0.2em;
        font-weight: 600;
        color: $text-color;
        display: block;
        text-align: center;
        white-space: nowrap;

    }
    // background inside the circle
    &:after {
        position: absolute;
        top: $circle-width;
        left: $circle-width;
        display: block;
        content: " ";
        border-radius: 50%;
        background-color: $bg-color;
        width: 1 - (2 * $circle-width);
        height: 1 - (2 * $circle-width);

    }
    // the slice (mask)
    .slice {
        position: absolute;
        width: 1em;
        height: 1em;
        clip: rect(0em, 1em, 1em, 0.5em);
    }
    // circle to show the status
    .bar {
        @extend .pie;
    }
    // loop to create all needed elements automatically
    @for $i from 51 through 100 {
        &.p#{$i} {
            & .slice {
                @extend .rect-auto;
            }
            &.bar:after {
                @extend .pie-fill;
            }
            & .fill {
                @extend .pie;
                @extend .pie-fill;
            }
        }
    }
    // loop to rotate all 100 circles
    @for $i from 1 through 100 {
        &.p#{$i} .bar {
            $degs: (360/100 * $i);
            @include rotate(#{$degs}deg);
        }
    }
    
}