.Favorites{
    .ui.loader{
       margin-top: 0 !important
    }
    .accordion{
        .active i.plus::before{
            content: "\f068" !important
        }
        .content.active{
            box-shadow: 0 1px 2px 0 #CCCCCC !important;
            background-color: white;
            border-radius: 0.4em !important;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
            padding: 1em  !important;
        }
        .title{
            margin-top: 1.3em !important;
            border-radius: 0.5em !important;
            box-shadow: 0 1px 2px 0 #CCCCCC !important;
            padding: 1.5em !important;
            background-color: white;
            &.active{
                border-top-right-radius:0!important;
                border-top-left-radius:0!important;
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important
            }
            h2{
                display: inline;
                font-weight: 300
            }
            i.dropdown{
                display: none !important;
            }
            i.circular{
                float: right !important;
                color: #9D9D9D;
            }
            button{
                .label{
                    background-color: #235677 !important;
                    color: white;
                    margin-left: 1em;
                }
            }
        }
        .list>.item{
            padding: 1em .5em !important
        }
    }
   
}

.reviews{
    .header { margin-top: 0.5em !important}
    .sublist{
        padding: 0 1em !important;
        &:nth-child(even){
            background-color:#f9fafb !important
        }
        .description{
            margin-top: 0.5em !important;
        }
    }
    .content{
        .description{
            white-space: pre-line;
        }
    }
}