.UserForm {
    & > .fields {
        .field {
            display: block !important;
            @media screen and (max-width: 767px) {
                margin-bottom: 1em !important;
            }
        }
        .field:last-of-type {
            @media screen and (max-width: 767px) {
                margin-bottom: 0em !important;
            }
        }
    }
}